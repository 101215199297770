<template>
  <type-template
    :spot-occurrence-values="spotOccurrenceValues"
    :spot-shapes="spotShapes"
    :first-spots-occurrence="firstSpotsOccurrence"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import TypeTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-pigmentation/type/TypeTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { yes, female } from '@/modules/questionnaire/api/helpers';
import {
  SPOT_OCCURRENCE,
  SPOT_OCCURRENCE_VALUES_COMMON
} from '@/modules/questionnaire/api/constants';

const { field, requiredArrayField, requiredField } = fieldBuilder;

const FIELDS = [
  field('age'),
  field('gender'),
  field('takingHRT'),
  field('takingBirthControlPill'),
  requiredField('spotShapes'),
  requiredArrayField('firstSpotsOccurrence')
];

export default {
  name: 'Type',
  components: {
    TypeTemplate
  },
  mixins: [makeStep(FIELDS)],
  computed: {
    spotOccurrenceValues() {
      const values = [...SPOT_OCCURRENCE_VALUES_COMMON];

      if (this.age > 40) {
        values.push(SPOT_OCCURRENCE.AT_MIDDLE_AGE);
      }

      if (this.age > 20 && female(this.gender)) {
        values.push(SPOT_OCCURRENCE.DURING_PREGNANCY);
      }

      if (yes(this.takingHRT)) {
        values.push(SPOT_OCCURRENCE.AFTER_STARTING_HRT);
      }

      if (yes(this.takingBirthControlPill)) {
        values.push(SPOT_OCCURRENCE.AFTER_STARTING_BIRTH_CONTROL);
      }

      return values;
    }
  },
  watch: {
    spotShapes(newValue, oldValue) {
      if (!oldValue && this.firstSpotsOccurrence.includes('unsure')) {
        this.showNextStep();
      } else {
        this.scrollTo('#first-spots-occurrence');
      }
    },
    firstSpotsOccurrence(newValue, oldValue) {
      if (this.spotShapes && newValue.includes('unsure') && !oldValue.includes('unsure')) {
        this.showNextStep();
      }
    }
  }
};
</script>
